export const UNKNOWN = 'Unknown';
export const FOUND = 'Found';
export const RULED_OUT = 'Ruled Out';

const states = [
	UNKNOWN,
	FOUND,
	RULED_OUT,
];

export default states;
