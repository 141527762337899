export const BONE = 'Bone';
export const SINK = 'Sink';
export const PHOTO = 'Photo';
export const EVENT = 'Event';
export const MOTION = 'Motion';
export const TEMPERATURE = 'Temperature';
export const CRUCIFIX = 'Crucifix';
export const EMF = 'EMF';
export const SALT = 'Salt';
export const SMUDGE = 'Smudge';

const quests = [
	BONE,
	CRUCIFIX,
	EMF,
	EVENT,
	MOTION,
	PHOTO,
	SALT,
	SINK,
	SMUDGE,
	TEMPERATURE,
];

export default quests;
