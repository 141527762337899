export const EMF = 'EMF';
export const FINGERPRINTS = 'Fingerprints';
export const FREEZING = 'Freezing';
export const GHOST_WRITING = 'Ghost Writing';
export const SPIRIT_BOX = 'Spirit Box';
export const GHOST_ORB = 'Ghost Orb';

const evidences = [
	EMF,
	FINGERPRINTS,
	FREEZING,
	GHOST_ORB,
	GHOST_WRITING,
	SPIRIT_BOX,
];

export default evidences;
